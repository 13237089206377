import React, {useEffect, useRef, useState} from 'react';
import {Button, Input, Pagination, Table} from "antd";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const Attached = () => {
    const [isLoading,setIsLoading]=useState(false)
    const [amount,setAmount]=useState(0)
    const [current,setCurrent]=useState(1)
    const [data,setData]=useState([])
    const navigate =useNavigate()
    const [searchText, setSearchText] = useState({});

    const columns = [
        {
            title: 'No',
            dataIndex: 'name',
            key: 'name',
            render: (_, __, rowIndex) => rowIndex + 1
        },
        {
            title: 'Ariza ragami',
            dataIndex: 'appeal_id',
            key: 'appeal_id',
            render:(_,record)=>(
                <>{record?.appeal?.id}</>
            )
        },
        {
            title: 'Shartnoma raqami',
            dataIndex: 'contragent_number',
            key: 'contragent_number',
            render:(_,record)=>(
                <>{record?.appeal?.contract_number}</>
            )
        },

        {
            title: 'Kontragent nomi',
            key: 'contragent_name',
            dataIndex: 'contragent_name',

        },


        {
            title: 'Turi',
            key: 'device_type',
            dataIndex: 'device_type',
        },

        {
            title: 'Holat',
            key: 'device_result',
            dataIndex: 'device_result',
            render:(_)=>(
                <div style={{backgroundColor:'rebeccapurple',color:'white', width:'fit-content', borderRadius:'5px', padding:'2px', fontSize:'11px', paddingLeft:'3px',paddingRight:'3px', fontWeight:'bold' }}>
                    Biriktirilgan
                </div>
            )
        },
        {
            title: 'Amallar',
            key: 'number_contract',
            render:(_,record)=>(
                <Button  type='primary' style={{backgroundColor:'#52c41a'}} onClick={()=>navigate(`/compare/${record?.id}`)}>Qiyoslash</Button>
            )
        },

    ];

    const getData=()=>{
        axios.get(`https://test-xodim.e-metrologiya.uz/api/e-hajm/v1/attached-appeal-devices/?limit=9999${searchText?.appeal_id?`&appeal_id=${searchText?.appeal_id}`:''}${searchText?.contract_number?`&contract_number=${searchText?.contract_number}`:''}${searchText?.contragent_name?`&contragent_name=${searchText?.contragent_name}`:''}${searchText?.device_type?`&device_type=${searchText?.device_type}`:''}${searchText?.factory_number?`&factory_number=${searchText?.factory_number}`:''}`,{
            headers:{
                "Authorization":"Bearer "+localStorage.getItem('access')
            }
        }).then((res)=>{
            setData(res?.data?.data)
        }).catch((err)=>{
            console.log(err)
        })
    }
    useEffect(() => {
        getData()
    }, []);
    useEffect(() => {
        getData()
    }, [searchText]);


    return (


        <div
            style={{
                padding: 24,
                minHeight: 360,
                height:'90vh',
            }}
        >
            <div style={{display:'flex',gap:'10px'}}>
                <Input onChange={(e)=>setSearchText({
                    ...searchText,
                    appeal_id:e.target.value
                })} placeholder='Ariza raqami..'/>
                <Input onChange={(e)=>setSearchText({
                    ...searchText,
                    contract_number:e.target.value
                })} placeholder='Shartnoma raqami..'/>
                <Input onChange={(e)=>setSearchText({
                    ...searchText,
                    contragent_name:e.target.value
                })} placeholder='Kontragent nomi	..'/>
                <Input onChange={(e)=>setSearchText({
                    ...searchText,
                    device_type:e.target.value
                })} placeholder='Turi..'/>
                <Input onChange={(e)=>setSearchText({
                    ...searchText,
                    factory_number:e.target.value
                })} placeholder='Zavod raqami..'/>

            </div>
            <Table  loading={isLoading} columns={columns} dataSource={data} pagination={true} />
            <div className='pagination'>
                {amount>10?
                    <Pagination current={current}
                        // onChange={pagination}
                                simple defaultCurrent={1} total={amount} />
                    :
                    <></>
                }
            </div>
        </div>

    );
};
export default Attached;