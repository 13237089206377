import React, {useEffect, useRef, useState} from 'react';
import {Button, Pagination, Table} from "antd";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const New = () => {
    const [isLoading,setIsLoading]=useState(false)
    const [amount,setAmount]=useState(0)
    const [current,setCurrent]=useState(1)
    const [data,setData]=useState([])
    const navigate =useNavigate()

    const columns = [
        {
            title: 'No',
            dataIndex: 'name',
            key: 'name',
            render: (_, __, rowIndex) => rowIndex + 1
        },
        {
            title: 'Ariza ragami',
            dataIndex: 'appeal_id',
            key: 'appeal_id',
            render:(_,record)=>(
                <>{record?.api_info?.appeal?.id}</>
            )
        },
        {
            title: 'Shartnoma raqami',
            dataIndex: 'contragent_number',
            key: 'contragent_number',
            render:(_,record)=>(
                <>{record?.api_info?.appeal?.contract_number}</>
            )
        },

        {
            title: 'Kontragent nomi',
            key: 'contragent_name',
            dataIndex: 'contragent_name',
            render:(_,record)=>(
                <>{record?.api_info?.contragent_name}</>
            )
        },


        {
            title: 'Zavod raqami',
            key: 'factory_number',
            dataIndex: 'factory_number',
        },
        {
            title: 'Holat',
            key: 'device_result',
            dataIndex: 'device_result',
            render:(_)=>(
                <div style={{backgroundColor:'#1677ff',color:'white', width:'fit-content', borderRadius:'5px', padding:'2px', fontSize:'11px', paddingLeft:'3px',paddingRight:'3px', fontWeight:'bold' }}>
                    Yangi
                </div>
            )
        },
        {
            title: 'Amallar',
            key: 'number_contract',
            render:(_,record)=>(
                <Button  type='primary' style={{backgroundColor:'#52c41a'}} onClick={()=>navigate(`/compare/${record?.api_info?.id}`)}>Qiyoslash</Button>
            )
        },

    ];

    const getData=()=>{
        axios.get('https://api.e-hajm.uz/api/protocols/new/?limit=9999').then((res)=>{
            setData(res?.data?.results)
        }).catch((err)=>{
            console.log(err)
        })
    }
    useEffect(() => {
        getData()
    }, []);



    return (


        <div
            style={{
                padding: 24,
                minHeight: 360,
                height:'90vh',
            }}
        >
            <Table loading={isLoading} columns={columns} dataSource={data} pagination={true} />
            <div className='pagination'>
                {amount>10?
                    <Pagination current={current}
                        // onChange={pagination}
                                simple defaultCurrent={1} total={amount} />
                    :
                    <></>
                }
            </div>
        </div>

    );
};
export default New;