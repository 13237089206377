import React, { useEffect, useState } from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {Layout, Menu, Button, theme} from 'antd';
import {NavLink, Outlet, useLocation, useNavigate} from 'react-router-dom';
import './home.scss'
import axios from "axios";
import {RxExit} from "react-icons/rx";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "../../auth/authSlice";
const { Header, Sider, Content } = Layout;
const App = () => {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const currentRouteName = location.pathname;
    const navigate=useNavigate()
    const [selectMenuNum, setSelectMenu] = useState();
    const user = useSelector((state) => state.user.value)
    const dispatch = useDispatch()
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const menu = [
        {
            key: '0',
            label: '',
            dashed: true,
        },
        {
            key: '1',
            routeName: '/',
            label: <NavLink to={'/'}>Biriktirilgan shartnomalar</NavLink>,
        },
        {
            key: '5',
            routeName: '/new',
            label: <NavLink to={'/new'}>Yangi shartnomalar</NavLink>,
        },
        {
            key: '2',
            routeName: '/pending',
            label: <NavLink to={'/pending'}>Jarayondagi shartnomalar</NavLink>,
        },
        {
            key: '3',
            routeName: '/confirmed',
            label: <NavLink to={'/confirmed'}>Tasdiqlangan shartnomalar</NavLink>,
        },
        {
            key: '4',
            routeName: '/rejected',
            label: <NavLink to={'/rejected'}>Rad etilgan shartnomalar</NavLink>,
        }

    ];
    const logOutSystem=() =>{
        axios.post('https://test-xodim.e-metrologiya.uz/api/e-hajm/v1/auth/logout',"",{
            headers:{
                "Authorization":"Bearer "+localStorage.getItem('access')
            }
        }).then(()=>{
            localStorage.clear()
            navigate('/login')

        }).catch((err)=>{

        })
    }

    const checkRouter = () => {
            menu.forEach((item) => {
                if (item.routeName === currentRouteName) {
                    setSelectMenu(item.key);
                    console.log(item.key)

                }
            });
    };
    const getProfile =()    => {
        axios.get('https://test-xodim.e-metrologiya.uz/api/e-hajm/v1/profile',{
            headers:{
                "Authorization":"Bearer "+localStorage.getItem('access')
            }
        }).then((res)=>{
            dispatch(setUser(res?.data?.user))
            console.log(res)
        }).catch((err)=>{
            console.log(err)
        })
    }
    useEffect(() => {
        getProfile()
        checkRouter();
    }, []);
    useEffect(() => {
        checkRouter()
    }, [currentRouteName]);
    return (
        <Layout>
            <Sider style={{width:'400px !important'}} trigger={null} collapsible={false} >
                <div className="demo-logo-vertical" />
                <Menu  theme="dark" mode="inline" items={menu} selectedKeys={[`${selectMenuNum}`]}  />
            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: 0,
                        background: colorBgContainer,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingRight:'15px'
                    }}
                >
                    <div></div>
                    {/*<Button*/}
                    {/*    type="text"*/}
                    {/*    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}*/}
                    {/*    onClick={() => setCollapsed(!collapsed)}*/}
                    {/*    style={{*/}
                    {/*        fontSize: '16px',*/}
                    {/*        width: 64,*/}
                    {/*        height: 64,*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <div style={{display:'flex',alignItems:'center',gap:'5px'}}>
                        <div style={{display:'flex',flexDirection:'column',lineHeight:'normal',gap:'2px'}}>
                            <span style={{fontWeight: 'bold',fontSize:'14px'}}>{user?.fio}</span>
                            <span style={{fontWeight: 'bold',fontSize:'12px'}}> {user?.username}</span>
                        </div>
                        <Button type="primary" danger onClick={logOutSystem}><RxExit /></Button>
                    </div>

                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        height: '100%',
                        overflow: 'auto',
                        minHeight: 280,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
};

export default App;
