import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'user',
    initialState: {
        value: {},
    },
    reducers: {
        setUser: (state, action) => {
            state.value = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setUser } = authSlice.actions

export default authSlice.reducer
