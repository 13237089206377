import React, {useEffect, useState} from 'react';
import './template.scss';
import axios from "axios";
import {useParams} from "react-router-dom";
import {Button, QRCode} from "antd";
import {MdDeleteForever} from "react-icons/md";
import {FaPlay} from "react-icons/fa6";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {useSelector} from "react-redux";
import {toast, ToastContainer} from "react-toastify";

const Template = () => {
    const user = useSelector((state) => state.user.value)
    const [device, setDevice] = useState()
    const {id} = useParams()
    const [section, setSection] = useState([])
    const [date, setDate] = useState()
    const now = new Date();
    const [isUnfit, setIsUnfit] = useState({})
    const year = now.getFullYear();
    const [all, setAll] = useState({
        first: 0,
        second: 0,
    });
    const [protocol, setProtocol] = useState({})
    const [overall, setOverall] = useState('')
    const [nessecaryItems, setNesseceryItems] = useState({
        first: '',
        second: '',
        third: '',
        fourth: '',
    })
    const allFieldsFilled = () => {
        return Object.values(nessecaryItems).every(value => value.trim() !== '');
    };
    const dateChange = (e) => {
        setDate(e.target.value)
        changeNesseceryItems(e, 'first')
        console.log(e.target.value)
    }
    const addSession = () => {
        axios.post('https://api.e-hajm.uz/api/data/create/', {
            protocol_id: id,
            "api_info": device,
            "live_day": nessecaryItems?.first,
            "temperature": nessecaryItems?.second,
            "humidity": nessecaryItems?.third,
            "pressure": nessecaryItems?.fourth

        }).then((res) => {
            setProtocol(res?.data)
            console.log(res)
        }).catch((err) => {
            console.log(err)
        })
        console.log('nesery', nessecaryItems)
        const tempArr = [...section];
        tempArr.push({
            id: Math.floor(Math.random() * 100) + 1,
            column1: '',
            column2: '',
            column3: '',
            column4: '',
            column5: '',
            column6: '',
            column7: '',
            column8: '',
            column9: '',
            column10: '',
            column11: '',
            column12: '',
            column13: '',
            column14: '',
            column15: ''
        });
        setSection(tempArr);
    };
    const deleteRow = (id) => {
        const tempArr = section.filter((item) => item.id !== id);
        setSection(tempArr);
    };
    const changeInput = (e, id, column1) => {
        const tempArr = [...section];
        const index = tempArr.findIndex((item) => item.id === id);
        if (index > -1) {
            tempArr[index][column1] = e.target.value;

            if (column1 === 'column9') {
                tempArr[index].enableSquare = e.target.value?.length > 0;
            }

            if (column1 === 'column10' || column1 === 'column11') {
                const column6Value = tempArr[index].column10?.length > 0;
                const column7Value = tempArr[index].column11?.length > 0;
                tempArr[index].enableSlider = column6Value || column7Value;
            }
        }
        setSection(tempArr);
        console.log(section);
    };
    const transformData = (data) => {
        const transformed = {};

        data.forEach((item, index) => {
            const key = `${index + 1}`;
            transformed[key] = {
                j: item.column4 || 0,
                k: item.column5 || 0,
                m: item.column6 || 0,
                n: item.column7 || 0,
                s: item.column12 || 0,
                r: item.column13 || 0,
                conclusion: item.column15 || false
            };
        });

        return transformed;
    };
    const changeNesseceryItems = (e, value) => {
        setNesseceryItems({
            ...nessecaryItems,
            [value]: e.target.value
        })
    };
    const generatePDF = () => {

        const input = document.querySelector('.template'); // Select your div
        const elementsToHide = document.querySelectorAll('.no-print');
        elementsToHide.forEach(element => element.style.display = 'none');

        html2canvas(input, {scale: 1.5}).then((canvas) => { // Adjust scale to 1.5
            const imgData = canvas.toDataURL('image/jpeg', 0.8); // Convert to JPEG and set quality to 0.8
            const pdf = new jsPDF('l', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            const imgProps = pdf.getImageProperties(imgData);
            const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'JPEG', 0, position, pdfWidth, imgHeight, undefined, 'FAST'); // Use 'FAST' compression
            heightLeft -= pdfHeight;

            while (heightLeft > 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'JPEG', 0, position, pdfWidth, imgHeight, undefined, 'FAST'); // Use 'FAST' compression
                heightLeft -= pdfHeight;
            }

            // Convert the PDF to a Blob
            const pdfBlob = pdf.output('blob');
            const now = new Date();

            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
            const day = String(now.getDate()).padStart(2, '0');
            const hour = String(now.getHours()).padStart(2, '0');
            const minute = String(now.getMinutes()).padStart(2, '0');
            // Create a FormData object and append the PDF Blo
            const formData = new FormData();
            formData.append('protocol', protocol?.id);
            formData.append('file_unconfirmed', pdfBlob, `${year}_${month}_${day}_${hour}_${minute}.pdf)`);
            formData.append('file_confirmed', pdfBlob, `${year}_${month}_${day}_${hour}_${minute}.pdf)`);
            const formDataEhajm = new FormData();
            formDataEhajm.append('protocol_number', protocol?.protocol_number);
            formDataEhajm.append('file', pdfBlob, `${year}_${month}_${day}_${hour}_${minute}.pdf)`);
            formData.append("data",JSON.stringify({
                ...device,
                ...section,
                ...all,
                result:result
            }))

            // Send the FormData with axios
            axios.post('https://api.e-hajm.uz/api/protocol-file/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log(response.data);
            }).catch(error => {
                console.error('Error uploading the PDF:', error);
            });

            axios.post(`https://test-xodim.e-metrologiya.uz/api/e-hajm/v1/save-protocol/${id}`, formDataEhajm, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access'),
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                console.log(response.data);
            }).catch(error => {
                console.error('Error uploading the PDF:', error);
            });

            axios.patch(`https://api.e-hajm.uz/api/data/status/${id}/update/`, {
                status: 'pending'
            }).then((res) => {
                toast.success("Protocol jo'natildi", {
                    position: toast.POSITION.TOP_RIGHT
                })
                console.log(res)
            }).catch((err) => {
                toast.error(err?.response?.data ? err?.response?.data?.message : 'Xatolik yuz berdi', {
                    position: toast.POSITION.TOP_RIGHT
                })
            })
            elementsToHide.forEach(element => element.style.display = '');
        });

    };
    const handleDownload = () => {
        axios.patch(`https://api.e-hajm.uz/api/data/update/${id}/`, {
            summary: section
        }).then((res) => {
            console.log(res)
        }).catch((err) => {
            console.log(err)
        })
        axios.post('https://api.e-hajm.uz/api/conclusion/', transformData(section))
            .then((res) => {
                console.log(res);
                setOverall(res?.data?.conclusion);

            })
            .catch((err) => {
                console.log(err);
            });
    }


    const canculate = (item) => {
        const payload = {
            t: item?.column1,
            a: item?.column2,
            b: item?.column3,
            k: item?.column14
        };

        if (item?.enableSquare) {
            payload.diameter = item?.column9
            payload.is_circle = item?.enableSquare;
        } else {
            payload.height = item?.column11
            payload.width = item?.column10
            payload.is_square = item?.enableSlider
        }
        axios.post('https://api.e-hajm.uz/api/calculate/', payload).then((res) => {
            const tempArr = [...section];
            const index = tempArr.findIndex((itm) => itm.id === item?.id);
            if (index > -1) {
                const data = res?.data;
                tempArr[index] = {
                    ...tempArr[index],
                    column1: data?.request_body?.t,
                    column2: data?.request_body?.a,
                    column3: data?.request_body?.b,
                    column4: data?.result?.j,
                    column5: data?.result?.k,
                    column6: data?.result?.m,
                    column7: data?.result?.n,
                    column8: device?.allowed_error,
                    column9: data?.request_body?.diameter,
                    column10: data?.request_body?.width,
                    column11: data?.request_body?.height,
                    column12: data?.result?.s,
                    column13: data?.result?.r,
                    column14: data?.request_body?.k,
                    column15: data?.result?.conclusion
                };
            }
            setSection(tempArr);
            console.log(section)
            console.log(res?.data);
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        let tempFirst = 0;
        let tempSecond = 0;

        section?.forEach((item) => {
            const column2Value = item.column4 === '' ? 0 : parseInt(item.column4, 10);
            const column3Value = item.column3 === '' ? 0 : parseInt(item.column3, 10);

            tempFirst += column2Value;
            tempSecond += column3Value;
        });

        setAll({
            first: tempFirst,
            second: tempSecond,
        });
    }, [section]);

    useEffect(() => {
        axios.get(`https://test-xodim.e-metrologiya.uz/api/e-hajm/v1/attached-appeal-device/${id}`, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('access')
            }
        }).then((res) => {
            setDevice(res?.data?.data)
            console.log(res)
        }).catch((err) => {
            console.log(err)
        })
    }, [])
    const generateTableRows = (instruments) => {
        console.log(instruments)
        let rows = [];
        for (let i = 0; i < instruments?.length; i += 2) {
            rows.push(
                <tr key={i}>
                    <td>{instruments[i]?.name}</td>
                    <td>{instruments[i]?.certificate}</td>
                    {instruments[i + 1] ? (
                        <>
                            <td>{instruments[i + 1]?.name}</td>
                            <td>{instruments[i + 1]?.certificate}</td>
                        </>
                    ) : (
                        <>

                        </>
                    )}
                </tr>
            );
        }
        return rows;
    };
    let result = ((all?.second - all?.first) / all?.first * 100);
    result = (isNaN(result) || !isFinite(result)) ? 0 : result.toFixed(2);
    const changeSelect = (e, order) => {
        if (order === 1) {
            setIsUnfit({
                ...isUnfit,
                1: {
                    status: e?.target?.value
                }
            })
        }
        if (order === 2) {
            setIsUnfit({
                ...isUnfit,
                2: {
                    status: e?.target?.value
                }
            })
        }
        console.log(isUnfit)
    }

    return (
        <div style={{height: 'auto'}}>
            <ToastContainer limit='1'/>

            <div className="template">
                <div>
                    <QRCode
                        value={"F.I.O: " + user?.fio + '\n' + 'Protocol raqami: ' + protocol?.protocol_number + '\n' + 'Sana: ' + date}/>
                </div>
                <header>
                    <div className="header-left" style={{textAlign: 'center'}}>
                        <p>O'lchov vositasini qiyoslash <br/> BAYONNOMASI</p>
                        <p>Bayonnoma
                            raqami: <span>{protocol?.protocol_number ? <>{protocol?.protocol_number}-{year}</> : ""}</span>
                        </p>
                        <p>Tadqiq qilish sanasi: <span><input style={{width: '100px', fontWeight: 'bold'}}
                                                              onChange={dateChange} type='date'/></span></p>
                    </div>
                    <div className="header-right">
                        <div className="stamp">
                            <p>“TASDIQLAYMAN”</p>
                            <p>O'z MMI DM, Geometrik va mexanik <br/>
                                kattaliklar o'lchash bo'limi boshlig'i</p>

                            <p>___________ {device?.department_head}</p>
                            <p>{date}</p>
                        </div>
                    </div>
                </header>

                <main>
                    <div className="info-section">
                        <div style={{display: 'flex', gap: '10px', alignItems: 'start'}}>
                            <div style={{width: '20%'}}><p><strong>1. Laboratoriya nomi va manzili:</strong></p></div>
                            <div style={{width: '80%', textAlign: 'start'}}><p>{device?.labaratory_info}</p></div>
                        </div>
                        <div style={{display: 'flex', gap: '10px', alignItems: 'start'}}>
                            <div style={{width: '20%'}}><p><strong>2. Buyurtmachi nomi:</strong></p></div>
                            <div style={{width: '80%', textAlign: 'start'}}><p> {device?.contragent_name}</p></div>
                        </div>
                        <div style={{display: 'flex', gap: '10px', alignItems: 'start'}}>
                            <div style={{width: '20%'}}><p><strong>3. Qiyoslanayotgan obyekt:</strong></p></div>
                            <div style={{width: '80%', textAlign: 'start'}}><p>{device?.object_name}</p></div>
                        </div>
                        <div style={{display: 'flex', gap: '10px', alignItems: 'start'}}>
                            <div style={{width: '20%'}}><p><strong>4. Shassi raqami (VIN):</strong></p></div>
                            <div style={{width: '80%', textAlign: 'start'}}><p>{device?.shassi_number}</p></div>
                        </div>
                        <div style={{display: 'flex', gap: '10px', alignItems: 'start'}}>
                            <div style={{width: '20%'}}><p><strong>5. Ishlab chiqaruvchi zavod:</strong></p></div>
                            <div style={{width: '80%', textAlign: 'start'}}><p>{device?.manufacturer}</p></div>
                        </div>
                        <div style={{display: 'flex', gap: '10px', alignItems: 'start'}}>
                            <div style={{width: '20%'}}><p><strong>6. Qiyoslanayotgan obyekt turi:</strong></p></div>
                            <div style={{width: '80%', textAlign: 'start'}}><p>{device?.object_type}</p></div>
                        </div>
                        <div style={{display: 'flex', gap: '10px', alignItems: 'start'}}>
                            <div style={{width: '20%'}}><p><strong>7. Autosisterna raqami:</strong></p></div>
                            <div style={{width: '80%', textAlign: 'start'}}><p>{device?.auto_systern_number}</p></div>
                        </div>
                        <div style={{display: 'flex', gap: '10px', alignItems: 'start'}}>
                            <div style={{width: '20%'}}><p><strong>8. Avtomobil raqami:</strong></p></div>
                            <div style={{width: '80%', textAlign: 'start'}}><p>{device?.auto_number}</p></div>
                        </div>

                        <div style={{display: 'flex', gap: '10px', alignItems: 'start'}}>
                            <div style={{width: '20%'}}><p><strong>9. Qiyoslash o'tkaziladigan joy:</strong></p></div>
                            <div style={{width: '80%', textAlign: 'start'}}><p>{device?.camparative_methodology}</p>
                            </div>
                        </div>
                        <div style={{display: 'flex', gap: '10px', alignItems: 'start'}}>
                            <div style={{width: '20%'}}><p><strong>10. Qiyoslash uslubiyoti:</strong></p></div>
                            <div style={{width: '80%', textAlign: 'start'}}><p>{device?.device_name} Zavod
                                raqami: <span>1; 2.</span> ruxsat etilgan
                                xatolik: <span style={{fontWeight: 'bold'}}>±{device?.allowed_error}</span>
                            </p></div>
                        </div>
                        <div style={{display: 'flex', gap: '10px', alignItems: 'start'}}>
                            <div style={{width: '20%'}}><p><strong>11. Qiyoslash o'tkazish sharoitlari:</strong></p>
                            </div>
                            <div style={{width: '80%', textAlign: 'start'}}>
                                <p> Havo harorati: <span><input onChange={(e) => changeNesseceryItems(e, 'second')}
                                                                type='number'/> °C</span> nisbiy namlik: <span><input
                                    type='number'
                                    onChange={(e) => changeNesseceryItems(e, 'third')}/> %</span> atmosfera
                                    bosimi: <span><input type='number'
                                                         onChange={(e) => changeNesseceryItems(e, 'fourth')}/> kPa</span>
                                </p>
                            </div>
                        </div>
                        <div style={{display: 'flex', gap: '10px', alignItems: 'start'}}>
                            <div style={{width: '20%'}}><p><strong>12. Na'munaviy o'lchash vositasi:</strong></p></div>
                            <div style={{width: '80%', textAlign: 'start'}}>
                                <table>
                                    <tbody>
                                    {generateTableRows(device?.instruments)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div style={{display: 'flex', gap: '10px', alignItems: 'start'}}>
                            <div style={{width: '20%'}}><p><strong>13.Qiyoslash natijasi</strong></p>
                            </div>
                            <div style={{width: '80%', textAlign: 'start'}}>
                                {device?.type === "neft" &&
                                    <div>
                                        <p> ГОСТ 8.600-2011 ГСИ. Автоцистерны для жидких нефтепродуктов.
                                            Методика поверки
                                            <br/>
                                            - Tashqi ko‘rik natijasi: ГОСТ 8.600-2011 8.1-bandi
                                            talabalriga <select onChange={(e) => changeSelect(e, 1)} className='select'>
                                                <option  value="">--------</option>
                                                <option value="fit">muvofiq</option>
                                                <option value="unfit">nomuvofiq</option>
                                            </select>
                                            <br/>
                                            - Xavoni chiqib ketish tizimini tekshirish: ГОСТ 8.600-2011 8.7-
                                            bandiga <select onChange={(e) => changeSelect(e, 2)} className='select'>
                                                <option value="">--------</option>
                                                <option value="fit">muvofiq</option>
                                                <option value="unfit">nomuvofiq</option>
                                            </select>
                                            <br/>
                                            - Sisterna xajmini xajmiy usulda o‘lchash natijalarii: ГОСТ 8.600-2011
                                            8.2-bandi.
                                        </p>
                                    </div>
                                }
                                {device?.type === "oziq" &&
                                    < div>
                                        ГОСТ 8.404-80. ГСИ. Автоцистерны для пищевых жидкостей. Методы и средства
                                        поверки.
                                        - Tashqi ko‘rik natijasi: ГОСТ 8.404-80 5.1-bandi
                                        talabalriga
                                        <select onChange={(e) => changeSelect(e, 1)} className='select'>
                                            <option style={{color:'red'}} value="">--------</option>
                                            <option value="fit">muvofiq</option>
                                            <option value="unfit">nomuvofiq</option>
                                        </select>
                                        <br/>
                                        - Xavoni chiqib ketish tizimini tekshirish: ГОСТ 8.404-80
                                        5.4-bandi <select onChange={(e) => changeSelect(e, 2)} className='select'>
                                        <option color='red' value="">--------</option>
                                        <option value="fit">muvofiq</option>
                                        <option value="unfit">nomuvofiq</option>
                                    </select>
                                        <br/>
                                        - Sisterna xajmini xajmiy usulda o‘lchash natijalarii: ГОСТ 8.404-80 5.2-bandiga
                                    </div>
                                }
                            </div>
                        </div>
                        {isUnfit?.[1]?.status === 'unfit' || isUnfit?.[2]?.status === 'unfit' ?
                            <div style={{display: 'flex', gap: '10px', alignItems: 'start', marginTop: '10px'}}>
                                <div style={{width: '20%'}}>
                                    <p><strong>14. Izoh:</strong></p>
                                </div>
                                <div style={{width: '80%', textAlign: 'start'}}>
                                    <input style={{width: '800px', textAlign: 'start'}}/>
                                </div>
                            </div>
                            :
                            <></>
                        }

                    </div>

                    <div className="result-section">
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            marginBottom: '5px'
                        }}><Button className='no-print' disabled={!allFieldsFilled()} onClick={addSession}>Seksiya
                            qo'shish</Button></div>
                        <table style={{textAlign: 'center'}}>
                            <thead>
                            <tr>
                                <th style={{background: '#C7C8CC'}} rowSpan="3">Seksiya</th>
                                <th style={{background: '#C7C8CC'}} rowSpan="3">Qiyoslashda suv harorati t, °C
                                </th>
                                <th style={{background: '#C7C8CC'}} colSpan="3">Sig'im hajmi, dm³</th>
                                <th style={{background: '#C7C8CC'}} colSpan="4">Nisbiy xatolik, %</th>
                                <th style={{background: '#C7C8CC'}} colSpan="6">Gorlovina</th>


                                <th style={{background: '#C7C8CC'}} rowSpan='3'>Xulosa
                                    (Muvofiq, Nomuvofiq)

                                </th>


                            </tr>
                            <tr>
                                <th style={{background: '#C7C8CC'}} rowSpan="3" width='70px'>ТМ haqiqiy hajmini,
                                    qiyoslashda o'rnatilgan, Vд1
                                </th>
                                <th style={{background: '#C7C8CC'}} rowSpan="3" width='70px'>"ТМni haqiqiy hajmini,
                                    markirovka tablichkasida ko'rsatilgan, Vд,
                                </th>
                                <th style={{background: '#C7C8CC'}} rowSpan="3" width='70px'>ТМ hajmi, 20 °C
                                    keltirilgan, Vт1
                                </th>
                                <th style={{background: '#C7C8CC'}} rowSpan="3">Nominal va haqiqiy hajm farqi ТМ, δт1
                                </th>
                                <th style={{background: '#C7C8CC'}} colSpan='2'>Nisbiy xatolik, haqiqiy hajmi 20 °C,
                                    keltirilganda, %
                                </th>
                                <th style={{background: '#C7C8CC'}} rowSpan='3'>Ruxsat etilgan xatolik, %
                                </th>
                                <th style={{background: '#C7C8CC'}} rowSpan="3">Gorlovina, d, (uzunligi- L), mm
                                </th>
                                <th style={{background: '#C7C8CC'}} colSpan='2'>To'rtburchak
                                </th>
                                <th style={{background: '#C7C8CC'}} rowSpan="3">Suv yuzasi sathiga ruxsat etilgan
                                    balandlik, ko'p emas, mm,
                                    ∆h1,2=
                                    0,0262* Dгор (L)
                                </th>
                                <th style={{background: '#C7C8CC'}} colSpan='2'>Sath ko'rsatgichdan lyukgacha bo'lgan
                                    balandlik, hr, (cm),
                                    hr=(0,005*109/S)*Vnom, kam emas
                                </th>
                            </tr>
                            <tr>
                                <td style={{background: '#C7C8CC', fontWeight: 'bold'}}>δ₁</td>
                                <td style={{background: '#C7C8CC', fontWeight: 'bold'}}>δ₂</td>
                                <td style={{background: '#C7C8CC', fontWeight: 'bold'}}>a</td>
                                <td style={{background: '#C7C8CC', fontWeight: 'bold'}}>b</td>
                                <td style={{background: '#C7C8CC', fontWeight: 'bold'}}>ruxsat</td>
                                <td style={{background: '#C7C8CC', fontWeight: 'bold'}}>fakt</td>
                            </tr>
                            </thead>
                            <tbody>
                            {section?.map((item, index) => (
                                <tr key={index}>
                                    <td style={{fontWeight: 'bold'}} width={70}>{index + 1}-seksiya</td>
                                    <td><input value={item?.column1}
                                               onChange={(e) => changeInput(e, item?.id, 'column1')} type='number'/>
                                    </td>
                                    <td><input value={item?.column2}
                                               onChange={(e) => changeInput(e, item?.id, 'column2')} type='number'/>
                                    </td>
                                    <td><input value={item?.column3}
                                               onChange={(e) => changeInput(e, item?.id, 'column3')} type='number'/>
                                    </td>
                                    <td style={{background: '#C7C8CC'}}>{item?.column4}</td>
                                    <td style={{background: '#C7C8CC'}}>{item?.column5}</td>
                                    <td style={{background: '#C7C8CC'}}>{item?.column6}</td>
                                    <td style={{background: '#C7C8CC'}}>{item?.column7}</td>
                                    <td style={{background: '#C7C8CC'}}>{item?.column8 && <>±</>}{item?.column8}</td>

                                    <td width={70}><input value={item?.column9} disabled={item?.enableSlider}
                                                          onChange={(e) => changeInput(e, item?.id, 'column9')}
                                                          type='number'/>
                                    </td>

                                    <td><input value={item?.column10} disabled={item?.enableSquare}
                                               onChange={(e) => changeInput(e, item?.id, 'column10')}
                                               type='number'/>
                                    </td>
                                    <td><input value={item?.column11} disabled={item?.enableSquare}
                                               onChange={(e) => changeInput(e, item?.id, 'column11')}
                                               type='number'/>
                                    </td>
                                    <td style={{background: '#C7C8CC'}}>{item?.column12}</td>
                                    <td style={{background: '#C7C8CC'}}>{item?.column13}</td>

                                    <td><input value={item?.column14}
                                               onChange={(e) => changeInput(e, item?.id, 'column14')}
                                               type='number'/>
                                    </td>
                                    <td style={{background: '#C7C8CC'}}>{item?.column15 === true ? "Muvofiq" : item?.column15 === '' ? '' : "Nomuoviq"}</td>

                                    <td className='no-print'
                                        style={{color: 'red', fontSize: '23px', cursor: 'pointer'}}
                                        onClick={() => deleteRow(item?.id)}><MdDeleteForever/></td>
                                    <td className='no-print'
                                        style={{color: 'green', fontSize: '20px', cursor: 'pointer'}}
                                        onClick={() => canculate(item)}><FaPlay/></td>


                                </tr>
                            ))

                            }
                            {section?.length > 0 && <tr>
                                <td style={{fontWeight: 'bold'}}>JAMI</td>
                                <td></td>
                                <td></td>
                                <td style={{background: '#C7C8CC'}}>{all?.second}</td>
                                <td style={{background: '#C7C8CC'}}>{all?.first}</td>
                                <td></td>
                                <td></td>
                                <td style={{background: '#C7C8CC'}}>{result}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td style={{background: '#C7C8CC'}}>{overall === true ? "Muvofiq" : overall === '' ? '' : "Nomuoviq"}</td>
                            </tr>

                            }
                            </tbody>
                        </table>
                    </div>

                    <div className="conclusion">
                        {device?.type === "oziq"?<>
                            Хulosa: Qiyoslash natijasiga ko`ra avtosisterna ГОСТ 8.404-80 “ГСИ. Автоцистерны для пищевых
                            жидкостей. Методы и средства поверки” 5.1, 5.2, 5.7-bandlari
                            talablariga {overall === true ? "muvofiq" : overall === '' ? '' : "nomuoviq"}
                        </>:<>
                          Xuloas: Qiyoslash natijasiga ko`ra avtosisterna  ГОСТ 8.600-2011 “ГСИ. Автоцистерны для жидких нефтепродуктов. Методика поверки”  8.1, 8.2, 8.7-bandlari  talablariga  {overall === true ? "muvofiq" : overall === '' ? '' : "nomuoviq"}
                        </>}

                        <p><strong>Qiyoslashni bajardi:</strong> {user?.fio}</p>
                    </div>
                </main>
            </div>

            <div style={{
                display: 'flex',
                width: '100%',
                alignItems: 'end',
                justifyContent: 'flex-end',
                padding: '20px',
                gap: '10px'
            }}>
                <Button onClick={handleDownload}>
                    Generate PDF
                </Button>
                <Button onClick={generatePDF}>Saqlash</Button>
            </div>
        </div>
    );
};

export default Template;
