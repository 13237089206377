import {AuthProvider} from "./auth/auth";
import {Route, Routes} from "react-router-dom";
import {RequireAuth} from "./auth/RequireAuth";
import Home from "./components/home/home";
import './style.scss'
import 'react-toastify/dist/ReactToastify.css';
import Rejected from "./components/rejected/rejected";
import Confirmed from "./components/confirmed/confirmed";
import New from "./components/new/new";
import Login from "./auth/login";
import Template from "./components/pdf/pdf_preparation/template";
import TemplateUpdate from "./components/pdf/pdf_preparation_update/template";
import Pending from "./components/pending/pending";
import Attached from "./components/attached/attached";
import TemplateAttached from "./components/pdf/pdf_preparation_attached/template";

function App() {
    return (
        <div className="App">
            <AuthProvider>
                <Routes>
                    <Route path='/login' element={<Login/>}/>
                    <Route
                        path='/'
                        element={
                            <RequireAuth>
                                <Home/>
                            </RequireAuth>
                        }
                    >
                        <Route path='/' element={<Attached/>}/>
                        <Route path='new' element={<New/>}/>
                        <Route path='compare/:id' element={<Template/>}/>
                        <Route path='compare/attached/:id' element={<TemplateAttached/>}/>
                        <Route path='update/:id' element={<TemplateUpdate/>}/>
                        <Route path='confirmed' element={<Confirmed/>}/>
                        <Route path='rejected' element={<Rejected/>}/>
                        <Route path='pending' element={<Pending/>}/>
                    </Route>
                </Routes>
            </AuthProvider>
        </div>
    );
}

export default App;
