import React, {useEffect} from 'react';
import {Button} from "antd";
import './login.scss'
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import 'react-phone-input-2/lib/bootstrap.css'

const Login = () => {
    const navigate = useNavigate();

    const test = () => {
        window.location.href = 'https://test-xodim.e-metrologiya.uz/oauth/authorize?client_id=31&redirect_uri=https://e-hajm.uz/login&response_type=code&scope='
    }
    const loginToSystem = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            try {
                axios.post('https://test-xodim.e-metrologiya.uz/oauth/token', {
                    client_id: 31,
                    client_secret: 'eY94wui06PTk5FcwibnqBhYc1kzJkkOnOZ9ChAvv',
                    grant_type: 'authorization_code',
                    redirect_uri: 'https://e-hajm.uz/login',
                    code: code
                }).then((res) => {
                    console.log(res)
                    localStorage.setItem('access', res?.data?.access_token)
                    localStorage.setItem('refresh', res?.data?.refresh_token)
                    navigate('/')
                }).catch((err) => {
                    console.log(err)
                    toast.error(err.response.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
            } catch (error) {

            }
        }
    }
    useEffect(() => {
        loginToSystem()
    }, [])
    return (
        <div className='login'>
            <div className='form-container'>
                <ToastContainer limit='1'/>

                <Button onClick={test} size='large' style={{width: '100%', height: '50px'}}>
                    Kirish
                </Button>
            </div>
        </div>
    );
};

export default Login;