import React, {useEffect, useRef, useState} from 'react';
import {Button,  Input, Pagination, Space, Table} from "antd";

import { SearchOutlined} from "@ant-design/icons";

import Highlighter from 'react-highlight-words';
import axios from "axios";
import {useNavigate} from "react-router-dom";

const Complated = () => {
    const [isLoading,setIsLoading]=useState(false)
    const [amount,setAmount]=useState(0)
    const [current,setCurrent]=useState(1)
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [data,setData]=useState([])
    const searchInput = useRef(null);
    const navigate =useNavigate()

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters,selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'No',
            dataIndex: 'name',
            key: 'name',
            render: (_, __, rowIndex) => rowIndex + 1
        },
        {
            title: 'Ariza ragami',
            dataIndex: 'appeal_id',
            key: 'appeal_id',
            render:(_,record)=>(
                <>{record?.api_info?.appeal?.id}</>
            )
        },
        {
            title: 'Shartnoma raqami',
            dataIndex: 'contragent_number',
            key: 'contragent_number',
            render:(_,record)=>(
                <>{record?.api_info?.appeal?.contract_number}</>
            )
        },

        {
            title: 'Kontragent nomi',
            key: 'contragent_name',
            dataIndex: 'contragent_name',
            render:(_,record)=>(
                <>{record?.api_info?.contragent_name}</>
            )
        },


        {
            title: 'Zavod raqami',
            key: 'factory_number',
            dataIndex: 'factory_number',
        },

        {
            title: 'Qiyoslash natijasi',
            key: 'device_result',
            dataIndex: 'device_result',
            render:(_)=>(
                <>Muvofiq</>
            )
        },
        {
            title: 'Holat',
            key: 'device_result',
            dataIndex: 'device_result',
            render:(_)=>(
                <div style={{backgroundColor:'green',color:'white', width:'fit-content', borderRadius:'5px', padding:'2px', fontSize:'11px', paddingLeft:'3px',paddingRight:'3px', fontWeight:'bold' }}>
                    Tasdiqlangan
                </div>
            )
        },


    ];

    const getData=()=>{
        axios.get('https://api.e-hajm.uz/api/protocols/confirmed/?limit=9999').then((res)=>{
            setData(res?.data?.results)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters,selectedKeys, confirm, dataIndex) => {
        clearFilters();
        setSearchText('');
        handleSearch('', confirm, dataIndex)
    };


    useEffect(() => {
        getData()
    }, []);



    return (


        <div
            style={{
                padding: 24,
                minHeight: 360,
                height:'90vh',
            }}
        >
            <Table loading={isLoading} columns={columns} dataSource={data} pagination={true} />
            <div className='pagination'>
                {amount>10?
                    <Pagination current={current}
                        // onChange={pagination}
                                simple defaultCurrent={1} total={amount} />
                    :
                    <></>
                }
            </div>
        </div>

    );
};
export default Complated;